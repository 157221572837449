import React from 'react'
import img1 from "../assets/images/courses/img1.svg"
import img2 from "../assets/images/courses/img2.jpg"

import  img3 from "../assets/images/courses/img3.png"
import img4 from "../assets/images/courses/img4.png"
import img5 from "../assets/images/courses/img5.png"

import img6 from "../assets/images/courses/img6.png"
import img7 from "../assets/images/courses/img7.png"
import img8 from "../assets/images/courses/img8.png"
import Header from '../components/Header'
import Footer from '../components/Footer'
const Services = () => {
  return (
    <div>
    <Header/>
       <main>
        {/* <!-- ============abt-01 Section  Start============ -->
         */}
        <section class="abt-01">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="heading-wrapper">
                            <h3>Services</h3>
                            <ol>
                                <li>Home<i class="far fa-angle-double-right"></i></li>
                                <li>Services</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- ============bg-se-02  Section  Start============ --> */}

        <section className="bg-se-02">
            <div className="container">
                <div className="row">
                    <div className="heading">
                        <h2>POPULAR COURSES</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img1}/>
                                </div>
                                <div className="sentence">
                                    <h3>DCA</h3>
                                    <p>Our DCA program is designed to provide a strong foundation in computer applications. The curriculum covers essential topics such as operating systems, databases, and software development, preparing students for various IT roles.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img2}/>
                                </div>
                                <div className="sentence">
                                    <h3>PGDCA</h3>
                                    <p>The PGDCA program offers advanced knowledge in computer applications. It includes in-depth training in programming languages, web development, and network management, aimed at graduates seeking to enhance their technical skills.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img3}/>
                                </div>
                                <div className="sentence">
                                    <h3>Tally</h3>
                                    <p>This course focuses on Tally, a popular accounting software. Students will learn how to manage financial accounts, handle taxation, and generate financial reports, making them proficient in accounting practices.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                   

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img4}/>
                                </div>
                                <div className="sentence">
                                    <h3>Basic</h3>
                                    <p>Our Basic Computer Course is perfect for beginners. It covers fundamental computer skills, including operating systems, word processing, spreadsheets, and internet usage, ensuring students are confident in using computers for everyday tasks.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img5}/>
                                </div>
                                <div className="sentence">
                                    <h3>Typing</h3>
                                    <p>Our Typing course focuses on improving typing speed and accuracy. Students will practice touch typing techniques, learn to use typing software, and develop the skills necessary for efficient and error-free typing, which is essential for many administrative and clerical jobs.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img6}/>
                                </div>
                                <div className="sentence">
                                    <h3>Photos studio job work</h3>
                                    <p>The Photo Studio course provides training in digital photography and photo editing. Students will learn techniques for capturing and editing images, using software like Adobe Photoshop to create stunning visuals.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img7}/>
                                </div>
                                <div className="sentence">
                                    <h3>Designing</h3>
                                    <p>This course is for aspiring designers. It covers graphic design principles, software tools such as Adobe Illustrator and CorelDRAW, and practical projects to build a strong portfolio.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <img src={img8}/>
                                </div>
                                <div className="sentence">
                                    <h3>Data entry</h3>
                                    <p>The Data Entry course trains students in accurate and efficient data input. It includes typing skills, data management, and using database software, preparing students for roles in data management and administration.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                  

                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="main-wrapper">
                            <div className="content text-center">
                                <div className="icon">
                                    <i className="fal fa-life-ring"></i>
                                </div>
                                <div className="sentence">
                                    <h3>Operator</h3>
                                    <p>The Operator course is designed to train students in the essential skills required to operate and manage computer systems effectively. The course covers basic computer operations, troubleshooting common issues, and understanding the functionalities of different software applications. This course prepares students for roles in IT support and system administration.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

       

        
    </main>

    <Footer/>
    </div>
  )
}

export default Services
