import React from 'react'
import logo from '../assets/images/logo.png'
import { Link } from 'react-router-dom'
const Header = () => {
    function showmenu(){
        const menu = document.querySelector(".menu")
        menu.classList.toggle("active")
    }
  return (
    <div>
         <header>
        <div className="my-nav">
            <div className="container">
                <div className="row">
                    <div className="nav-items">
                        <div className="menu-toggle" onClick={showmenu}>
                            <div className="menu-hamburger"></div>
                        </div>
                        <div className="logo">
                            <img src={logo}/>
                        </div>
                        <div className="menu-items">
                            <div className="menu">
                                <ul>
                                    <li>
                                    <Link to="/">
                                    <a href="#">Home</a>
                                    </Link>
                                    </li>
                                    <li>
                                    <Link to="/about">

                                    <a href="#">About Us</a>
                                    </Link>
                                    </li>
                                    <li>
                                    <Link to="/services">
                                    <a href="3">Services</a>
                                    </Link>
                                    </li>
                                    <li>
                                    <Link to="/blog">
                                    <a href="#">Blog</a>
                                    </Link>
                                    </li>
                                    <li>
                                    <Link to="/contact">
                                    
                                    <a href="#">Contact Us</a>
                                    </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        

                        
                            <button 
                            className='btn success ' 
                            style={{height:"40px", margin:"20px 0px 0px 20px"}}
                            
                            > 
                            <a href='https://demo.dakshbharatacademy.com/'>Enroll Now</a>
                            </button>
                    
                    </div>
                    
                </div>
            </div>
        </div>

        <div class="floating-widgets">
        <div class="whatsapp-widget-after">
            <div class="widgets1 whatsapp-widget">
                <a href="https://wa.me/92445 31970"><i class="fa-brands fa-whatsapp"></i></a>
            </div>
        </div>
        <div class="calling-widget-after">
            <div class="widgets1 calling-widget">
                <a href="tel:92445 31970"><i class="fa-solid fa-phone"></i></a>
            </div>
        </div>
    </div>
    </header>

    
    </div>
  )
}

export default Header
