import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'


import blogimg1 from "../assets/images/blog/img-01.jpg"
import blogimg2 from "../assets/images/blog/img-02.jpg"
import blogimg3 from "../assets/images/blog/img-03.jpg"
const Blog = () => {
  return (
    <div>
    <Header/>
    <main>
        {/* <!-- ============abt-01 Section  Start============ --> */}
        
        <section class="abt-01">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="heading-wrapper">
                            <h3>Blog</h3>
                            <ol>
                                <li>Home<i class="far fa-angle-double-right"></i></li>
                                <li>Blog</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- ============bg-se-05  Section  Start============ --> */}
        
        <section className="bg-se-05">
            <div className="container">
                <div className="row">
                    <div className="heading">
                        <h2>Latest News</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <article className="_lk_bg_sd_we">
                            <div className="_bv_xs_we">
                                <img src={blogimg1}/>
                            </div>
                            <div className="_xs_we_er">
                                <div className="_he_w">
                                    <h3> The Advantages of Learning from Experienced Faculty</h3>
                                    <ol>
                                        <li><span>by</span> admin<span className="_mn_cd_xs">August 20, 2023</span></li>
                                    </ol>
                                    <p>When it comes to quality education, the expertise and experience of the faculty play a crucial role. At Dakshbharat Academy, we pride ourselves on having a team of highly experienced and knowledgeable instructors who bring a wealth of practical insights to the classroom.
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <article className="_lk_bg_sd_we">
                            <div className="_bv_xs_we">
                                <img src={blogimg2}/>
                            </div>
                            <div className="_xs_we_er">
                                <div className="_he_w">
                                    <h3>How Comprehensive Curriculum Prepares You for the Future</h3>
                                    <ol>
                                        <li><span>by</span> admin<span className="_mn_cd_xs">April 10, 2024</span></li>
                                    </ol>
                                    <p>In today's fast-paced world, having a comprehensive education is essential for staying competitive in the job market. At Dakshbharat Academy, we offer a wide range of courses designed to equip you with the knowledge and skills needed to succeed in your career.
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <article className="_lk_bg_sd_we">
                            <div className="_bv_xs_we">
                                <img src={blogimg3}/>
                            </div>
                            <div className="_xs_we_er">
                                <div className="_he_w">
                                    <h3>The Benefits of Hands-on Training and Practical Experience</h3>
                                    <ol>
                                        <li><span>by</span> admin<span className="_mn_cd_xs">June 25, 2024</span></li>
                                    </ol>
                                    <p>In the world of computer education, hands-on training and practical experience are crucial for developing the skills needed to succeed in the industry. At Dakshbharat Academy, we emphasize practical, hands-on learning to ensure our students are well-prepared for their careers.
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>

   
    </main>

    <Footer/>
    </div>
  )
}

export default Blog
