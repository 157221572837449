import React from 'react'
import Header from '../components/Header'
import aboutImg1 from '../assets/images/about-image-1-min.jpg'
import Footer from '../components/Footer'
import bg1 from "../assets/images/bg/1.png"
import team1 from "../assets/images/team/1.jpg"
import team2 from "../assets/images/team/2.jpg"
import team3 from "../assets/images/team/3.jpg"
import team4 from "../assets/images/team/4.jpg"
import { Link } from 'react-router-dom'
const About = () => {
  return (
    <>
      <Header/>
    <main>

        {/* <!-- ============abt-01 Section  Start============ --> */}

        <section class="abt-01">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="heading-wrapper">
                            <h3>About Us</h3>
                            <ol>
                                <li>Home<i class="far fa-angle-double-right"></i></li>
                                <li>About Us</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- ============abt-02 Section  Start============ --> */}

        <section class="abt-02">
            <div class="container">
                <div class="row">
                    <div class="abt-main d-flex">
                        <div class="abt-spirit">
                            <div class="image-box">
                                <img src={aboutImg1}/>
                            </div>
                        </div>
                        <div class="abt-spirit">
                            <div class="abt-content-card">
                                <h2>About Us</h2>
                                <p>Founded in 2013, Dakshbharat Academy has been a pioneer in computer education. Over the years, we have grown and evolved, always staying at the forefront of technological advancements to provide our students with the best education possible.</p>
                                <h5 class="py-3">Team Members and Instructors</h5>
                                <p>Our team comprises highly skilled professionals with extensive experience in the field of computer science. They are passionate about teaching and are committed to helping students achieve their goals.</p>
                    
                                <span>
                                <Link to="/contact">    
                                    <a href="#">Contact us</a>
                                </Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



    

        {/* <!-- ============bg-se-04  Section  Start============ --> */}

        <section class="bg-se-04">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="_lk_bg_cd">
                            <i class="fal fa-history"></i>
                            <div class="counting" data-count="20">11</div>
                            <h5>YEAR OF HISTORY</h5>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="_lk_bg_cd">
                            <i class="fal fa-graduation-cap"></i>
                            <div class="counting" data-count="80">150</div>
                            <h5>ACTIVE STUDENTS</h5>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="_lk_bg_cd">
                            <i class="fal fa-globe"></i>
                            <div class="counting" data-count="27">10</div>
                            <h5>ONLINE COURSES</h5>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="_lk_bg_cd">
                            <i class="fal fa-registered"></i>
                            <div class="counting" data-count="62">200</div>
                            <h5>REGISTERED STUDENTS</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- ============bg-se-06  Section  Start============ --> */}

       

        {/* <!-- ============bg-se-01  Section  Start============ --> */}
        
        <section className="bg-se-01">
            <div className="container">
                <div className="row">
                    <div className="heading">
                        <h2>WHY CHOOS US</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 order-sm-2 order-2 order-md-0 order-lg-0 order-xl-0">
                        <div className="main-card">
                            <div className="sub-card">
                                <img src={bg1}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="main-card">
                            <div className="content">
                                <ol>
                                    <li>
                                        <h3>Experienced Faculty</h3>
                                        <p>Our instructors are industry experts with years of experience. They bring practical knowledge and insights to the classNameroom, ensuring that our students receive top-quality education.</p>
                                    </li>
                                    <li>
                                        <h3>Affordable Fees</h3>
                                        <p>Quality education should be accessible to everyone. We offer competitive fees and various scholarship programs to ensure that financial constraints do not hinder your learning journey.</p>
                                    </li>
                                    <li>
                                        <h3>Positive Learning Environment</h3>
                                        <p>We foster a supportive and inclusive learning environment where students feel valued and encouraged. Our community is built on respect, collaboration, and a shared passion for technology.</p>
                                    </li>
                                    <li>
                                        <h3>Flexible Learning Options</h3>
                                        <p>We offer flexible learning options, including online and offline classNamees, to accommodate the diverse needs of our students. Whether you are a working professional or a full-time student, we have a schedule that fits your lifestyle.</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>

    {/* <!-- ============Footer  Start============ --> */}

   <Footer/>
      
    </>
  )
}

export default About
